<template>
    <b-card class="chat-widget" no-body>
        <b-card-header>
            <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" right variant="outline-primary"
                :text="selectedGroup.name">
                <b-dropdown-item v-for="grp in groups" @click="selectedGroup=grp" :key="grp.id">
                    <b-avatar size="24" class="avatar-border-2 box-shadow-1" variant="transparent"
                        :src="grp.imageUrl" />
                    {{grp.name}}
                </b-dropdown-item>
            </b-dropdown>
            <!-- <div class="d-flex align-items-center" >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning">
                    <feather-icon icon="StarIcon" class="mr-50" />
                    <span class="align-middle">{{grp.name}}</span>
                </b-button>
            </div> -->
        </b-card-header>

        <section class="chat-app-window">
            <!-- User Chat Area -->
            <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings"
                class="user-chats scroll-area">
                <chat-log :chat-data="chatData" :profile-user-avatar="selectedGroup.imageUrl" />
            </vue-perfect-scrollbar>

            <!-- Message Input -->
            <b-form class="chat-app-form" @submit.prevent="sendMessage">
                <feather-icon size="32" :icon="imageUrl==''?'CameraIcon':'PaperclipIcon'" class="mr-50"
                    @click="addImage" />
                <input ref="fileInput" @change="handleImage" accept="image/*" type="file" style="display:none" />
                <b-input-group class="input-group-merge form-send-message mr-1">
                    <b-form-input v-model="chatInputMessage" placeholder="Göndermek istediğiniz mesajı yazınız..." />
                </b-input-group>
                <b-button variant="primary" type="submit">
                    Gönder
                </b-button>
            </b-form>
        </section>

    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BDropdown, BDropdownItem, BDropdownDivider
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from './ChatComponent/ChatLog.vue'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        BCard,
        BCardHeader,
        BAvatar,
        BForm,
        BFormInput,
        BInputGroup,
        BButton,
        BDropdown, BDropdownItem, BDropdownDivider,
        // SFC
        ChatLog,

        // 3rd party
        VuePerfectScrollbar,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
                wheelPropagation: false,
            },
            chatInputMessage: '',
            chatData: [],
            groups: [],
            selectedGroup: {},
            connection: null,
            me: {},
            imageUrl: ""
        }
    },
    watch: {
        selectedGroup: function (val) {
            if (val != null) {
                this.getMessages(val);
            }
        }
    },
    created() {
        this.me = JSON.parse(localStorage.getItem('userData'));
        if (this.me != undefined && this.me != null) {
            this.connection = new HubConnectionBuilder()
                .configureLogging(LogLevel.Information)
                .withUrl("https://api.uzaktanokulsistemi.com/chatHub", {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                })
                .build()
            this.connection.start()
        }

    },
    mounted() {
        this.psToBottom()
        this.getMessageGroup();
    },
    methods: {
        addImage() {
            this.$refs.fileInput.click()
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            this.getBase64(selectedImage);
        },
        getBase64(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            var scoped = this;
            reader.onload = function () {
                scoped.imageUrl = reader.result;
            };
            reader.onerror = function (error) {
                //console.log('Error: ', error);
            };
        },
        async sendMessage() {
            var messages = await this.$http.post("Message/AddMessage", {
                messageGroupId: this.selectedGroup.id,
                text: this.chatInputMessage,
                imageUrl: this.imageUrl
            });

            this.chatInputMessage = "";
            this.imageUrl = "";
            this.$nextTick(() => {
                this.psToBottom()
            })
        },
        async getMessages(group) {
            if (this.me != undefined && this.me != null) {
                var messages = await this.$http("Message/Messages/" + group.id);
                this.chatData = messages.data.data;
                this.$nextTick(() => {
                    this.psToBottom()
                })
            }
        },
        async getMessageGroup() {
            if (this.me != undefined && this.me != null) {
                var groups = await this.$http("Message/GroupTeacher");
                this.groups = groups.data.data;
                if (this.groups.length > 0) {
                    this.selectedGroup = this.groups[0];
                }
                this.groups.forEach(element => {
                    this.listener(element.id);
                });
            }
        },
        psToBottom() {
            const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
            scrollEl.scrollTop = scrollEl.scrollHeight
        },
        listener(Id) {
            this.connection.on(Id, (messageData) => {
                if (messageData.channelId == this.selectedGroup.id) {
                    this.chatData.push(messageData);
                    this.$nextTick(() => {
                        this.psToBottom()
                    })
                } else {
                    var groups = this.groups.filter(x => x.id == messageData.channelId)[0];
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: messageData.userName + "-" + groups.name,
                            text: messageData.messageText == "" ? 'Resim gönderdi' : messageData.messageText,
                            icon: "MailIcon",
                            variant: 'success',
                        }
                    }, { position: 'top-left' })
                }
                if (messageData.clientId != this.me.id) {
                    var audio = new Audio("https://dosya.turkiyeakademi.net/message.mp3");
                    audio.play();
                }
            });
        }
    },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
